<template>
  <dog-dialog
    ref="dogDialog"
    width="400px"
    :title="isEdit ? '编辑字典项' : '新增字典项'"
    @loading-confirm="confirm"
    @closed="closed"
  >
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
    ></dog-form>
  </dog-dialog>
</template>

<script>
  import dictionaryService from '@/api/service/dictionary.js';

  export default {
    name: 'addDictionaryItemDialog',
    data() {
      return {
        dictionaryValue: null,
        isEdit: false,
        formData: {},
        formOptions: [
          {
            type: 'input',
            formItem: {
              label: '字典项名称',
              prop: 'dictionaryItemName',
              rules: [this.$formRules.required('请输入字典项名称')]
            }
          },
          {
            type: 'input',
            formItem: {
              label: '字典项值',
              prop: 'dictionaryItemValue',
              rules: [this.$formRules.required('请输入字典项值')]
            }
          },
          {
            type: 'el-input-number',
            formItem: {
              label: '排序',
              prop: 'dictionaryItemSort',
              rules: [this.$formRules.required('请输入排序')]
            },
            attrs: {
              default: 1,
              min: 1,
              stepStrictly: true
            }
          },
          {
            type: 'switch',
            formItem: {
              label: '是否启用',
              prop: 'dictionaryItemEnable',
              rules: []
            },
            attrs: {
              default: true
            }
          }
        ]
      };
    },
    methods: {
      openFrame({ id, dictionaryValue }) {
        this.dictionaryValue = dictionaryValue;
        this.$refs.dogDialog.open();
        if (id) {
          this.isEdit = true;
          dictionaryService
            .getDictionaryItemDetail({ id, dictionaryValue })
            .then((res) => {
              this.formData = {
                ...res,
                dictionaryItemEnable: this.$methods.stringNumToBoolean(
                  res.dictionaryItemEnable
                )
              };
            });
        }
      },
      confirm(done) {
        this.$refs.dogForm
          .validate()
          .then(() => {
            return dictionaryService[
              this.isEdit ? 'editDictionaryItem' : 'addDictionaryItem'
            ]({
              ...this.formData,
              dictionaryValue: this.dictionaryValue,
              dictionaryItemEnable: this.$methods.booleanToStringNum(
                this.formData.dictionaryItemEnable
              )
            });
          })
          .then(() => {
            this.$message.success('保存成功！');
            done();
            this.$emit('confirm');
            this.close();
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.dictionaryValue = null;
        this.isEdit = false;
        this.formData = {};
      }
    }
  };
</script>

<style lang="scss" scoped></style>
