import baseService from '../baseService';
const request = baseService('dictionary');
import { stringNumToBoolean } from '@/utils/methods/common';

export default {
  getDictionaryList(params) {
    return request('/getDictionaryList', params, 'get').then((res) => {
      res.list.forEach((item) => {
        item.dictionaryEnable_bn = stringNumToBoolean(item.dictionaryEnable);
      });
      return res;
    });
  },
  deleteDictionaries(params) {
    return request('/deleteDictionaries', params, 'delete');
  },
  getDictionaryDetail(params) {
    return request('/getDictionaryDetail', params, 'get');
  },
  addDictionary(params) {
    return request('/addDictionary', params, 'post');
  },
  editDictionary(params) {
    return request('/editDictionary', params, 'put');
  },
  quickOperateDictionary(params) {
    return request('/quickOperateDictionary', params, 'put');
  },
  getDictionaryItemList(params) {
    return request('/getDictionaryItemList', params, 'get').then((res) => {
      res.list.forEach((item) => {
        item.dictionaryItemEnable_bn = stringNumToBoolean(
          item.dictionaryItemEnable
        );
      });
      return res;
    });
  },
  deleteDictionaryItems(params) {
    return request('/deleteDictionaryItems', params, 'delete');
  },
  getDictionaryItemDetail(params) {
    return request('/getDictionaryItemDetail', params, 'get');
  },
  addDictionaryItem(params) {
    return request('/addDictionaryItem', params, 'post');
  },
  editDictionaryItem(params) {
    return request('/editDictionaryItem', params, 'put');
  },
  quickOperateDictionaryItem(params) {
    return request('/quickOperateDictionaryItem', params, 'put');
  }
};
